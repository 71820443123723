import PropTypes from 'prop-types';
import cn from 'classnames';
import LinkWrapper from 'components/Common/LinkWrapper';
import styles from './styles.module.scss';

const ButtonMore = ({
  href,
  dynamicRouting,
  title,
  buttonStyle,
  handleOnClick,
  buttonRef,
  isDisabled,
  disabledButtonStyle,
  type,
  ...rest
}) => (!href.length ? (
  <div
    ref={buttonRef}
    className={cn({ [buttonStyle]: buttonStyle })}
    onClick={handleOnClick}
    role="button"
    tabIndex="0"
    {...rest}
  >
    {title}
  </div>
) : (
  <LinkWrapper
    isLocalLink
    path={href}
    dynamicRouting={dynamicRouting}
    className={cn(
      styles.link,
      styles[type],
      { [disabledButtonStyle]: isDisabled },
    )}
    {...rest}
  >
    <div
      className={cn({ [buttonStyle]: buttonStyle })}
      onClick={handleOnClick}
      role="button"
      tabIndex="0"
    >
      {title}
    </div>
  </LinkWrapper>
));

ButtonMore.defaultProps = {
  href: '',
  dynamicRouting: '',
  buttonStyle: null,
  type: '',
  buttonRef: null,
  handleOnClick: null,
  isDisabled: false,
  disabledButtonStyle: null,
};

ButtonMore.propTypes = {
  href: PropTypes.string,
  dynamicRouting: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
  title: PropTypes.string.isRequired,
  buttonStyle: PropTypes.string,
  type: PropTypes.string,
  handleOnClick: PropTypes.func,
  buttonRef: PropTypes.instanceOf(Object),
  isDisabled: PropTypes.bool,
  disabledButtonStyle: PropTypes.string,
};

export default ButtonMore;
